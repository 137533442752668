
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Decimal from "decimal.js";
import dayjs from "@/plugins/day-js";
import Batch from "@/types/batch";
import Modal from "@/components/Modal.vue";
import { mask } from "vue-the-mask";
import LoanService from "@/services/loan-service";
import PaymentService from "@/services/payment-service";
import CompanyService from "@/services/company-service";
import { ValidationProvider } from "vee-validate";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import BatchRegistersUpdateData, {
  BatchRegisterUpdateData
} from "@/services/loan-service/types/batch-registers-update-data";
import ListBatchRegistersParams from "@/services/loan-service/types/list-batch-registers-params";
import Payments from "@/services/payment-service/types/payments";
import Company from "@/types/company";
import { DataTableHeader } from "vuetify";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";
import { BatchStatusEnum } from "@/types/batch-status";
import { PaymentStatusEnum } from "@/types/payment-status";
import { PaymentTypeEnum } from "@/types/payment-type";
import { getBankSlipCurrentStatus } from "@/utils/bankslip";
import { ExtendedBatchRegister } from "@/types/batch-register";
import { SegmentsEnum } from "@/data/segmentos";

@Component({
  components: { ValidationObserver, ValidationProvider, Modal },
  directives: { mask }
})
export default class BatchesManageModal extends Vue {
  formatCNPJ = formatCNPJ;
  formatCPF = formatCPF;
  BatchStatusEnum = BatchStatusEnum;
  PaymentTypeEnum = PaymentTypeEnum;
  PaymentStatusEnum = PaymentStatusEnum;
  fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL;
  service: LoanService;
  paymentService: PaymentService;
  companyService: CompanyService;
  loading = true;
  loadingXLS = false;
  changed = false;
  batchRegisters: ExtendedBatchRegister[] = [];
  batchRegistersAfterConciliation: ExtendedBatchRegister[] = [];
  payments: Payments;
  loadingPayments: boolean;
  batchRegistersUpdateData: BatchRegistersUpdateData;
  paymentType: string;
  paymentLabel: string;
  paymentStatus: string;
  paymentAlert: string;
  company: Company;
  segments = SegmentsEnum;
  billingMode = "Boleto";
  nonReconciliationMotives = [
    {
      value: "FUNCIONARIO_DESLIGADO",
      text: "Funcionário desligado"
    },
    {
      value: "FUNCIONARIO_AFASTADO",
      text: "Funcionário afastado"
    },
    {
      value: "FUNCIONARIO_SEM_MARGEM",
      text: "Funcionário sem margem"
    },
    {
      value: "GERADO_APOS_CONCILIACAO",
      text: "Gerado após a conciliação"
    },
    ...(this.hasPermissions(["TRANSFERIR_CONTRATO"])
      ? [{ value: "TRANSFERIR_CONTRATO", text: "Transferência" }]
      : [])
  ];
  filters: ListBatchRegistersParams;
  headers: Array<DataTableHeader> = [
    { text: "Colaborador", value: "borrower" },
    { text: "Proposta", value: "loanInstallment.loan.id" },
    { text: "Parcela", value: "loanInstallment.number" },
    { text: "Prazo", value: "loanInstallment.loan.numInstallments" },
    { text: "Valor parcela", value: "loanInstallment.value" },
    { text: "Saldo devedor", value: "debitBalanceValue" },
    { text: "Consigna", value: "reconciliate" },
    { text: "Valor repasse", value: "reconciliationValue" },
    { text: "Motivo", value: "nonReconciliationMotive" },
    { text: "Verbas rescisórias", value: "severancePayValue" }
  ];
  getBankSlipCurrentStatus = getBankSlipCurrentStatus;

  @Prop() readonly batch!: Batch;
  @Prop() readonly editable!: Batch;
  @Prop() readonly showPayment!: Batch;

  constructor() {
    super();
    this.service = LoanService.getInstance();
    this.paymentService = PaymentService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.filters = {
      batchId: this.batch.id
    };
    this.batchRegistersUpdateData = {
      batchId: this.batch.id,
      batchRegisters: []
    } as BatchRegistersUpdateData;
    this.payments = {
      bankSlips: []
    };
    this.loadingPayments =
      this.batch.status.name === BatchStatusEnum.CLOSED.name;
  }

  async created() {
    if (this.batch) {
      await this.fetchBatchRegisters();

      const [getCompanyError, getCompanyData] =
        await this.companyService.getCompany(this.batch.companyId);

      if (getCompanyError) {
        const message = getErrorMessageFromApiError(getCompanyError);

        this.$notify({
          title: "Erro ao obter dados de empresa",
          type: "error",
          text: message
        });
      }

      if (getCompanyData) {
        this.company = getCompanyData;
      }

      if (this.company.segmentId == this.segments.Publico.id) {
        this.billingMode = "Cobrança";
      }

      if (this.batch.status.name === BatchStatusEnum.CLOSED.name) {
        this.fetchPayments();
      }
    }
  }

  async fetchPayments(): Promise<Payments> {
    this.loadingPayments = true;
    const [error, payments] = await this.paymentService.listPaymentsByBatchIds([
      this.batch.id
    ]);
    this.loadingPayments = false;

    if (!error) {
      this.payments = payments!;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }

    if (
      this.payments &&
      this.payments?.paymentOthers &&
      this.payments.paymentOthers.length
    ) {
      this.paymentType = "paymentOthers";
      this.paymentLabel = "Situação do pagamento";
      this.paymentStatus = "Status da cobrança";
      this.paymentAlert = "O cobrança já foi paga";
    }
    if (
      this.payments &&
      this.payments?.bankSlips &&
      this.payments.bankSlips.length
    ) {
      this.paymentType = "bankSlips";
      this.paymentLabel = "Situação do boleto";
      this.paymentStatus = "Status do boleto";
      this.paymentAlert = "O boleto já foi pago!";
    }

    return this.payments;
  }

  async fetchBatchRegisters(): Promise<ExtendedBatchRegister[]> {
    this.loading = true;
    const [error, batchRegisters] = await this.service.listBatchRegisters(
      this.filters
    );

    if (!error) {
      this.batchRegisters = batchRegisters!.filter(
        (batchRegister) =>
          batchRegister.generatedAfterConciliation === null ||
          batchRegister.generatedAfterConciliation === false
      );

      this.batchRegistersAfterConciliation = batchRegisters!.filter(
        (batchRegister) => batchRegister.generatedAfterConciliation === true
      );

      this.batchRegisters.forEach((batchRegister) => {
        const batchRegisterUpdateData = {
          id: batchRegister.id,
          reconciliate: batchRegister.reconciliate || false,
          nonReconciliationMotive: batchRegister.nonReconciliationMotive?.name,
          severancePayValue: batchRegister.severancePayValue,
          reconciliationValue:
            batchRegister.reconciliationValue ||
            batchRegister.loanInstallment.value,
          debitBalanceValue: batchRegister.loanInstallment.debitBalanceValue
        };

        this.batchRegistersUpdateData.batchRegisters.push(
          this.fixBatchRegisterData(batchRegisterUpdateData)
        );
      });
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }

    this.loading = false;
    return this.batchRegisters;
  }

  async updateBatchRegisters(): Promise<ExtendedBatchRegister[]> {
    this.loading = true;
    this.batchRegistersUpdateData.batchRegisters =
      this.batchRegistersUpdateData.batchRegisters.map((batchRegister) => {
        return this.fixBatchRegisterData(batchRegister);
      });

    const [error, batchRegisters] = await this.service.updateBatchRegisters(
      this.batchRegistersUpdateData
    );

    if (!error) {
      this.$notify({ type: "success", text: "Alterações salvas!" });
      this.changed = false;
      this.$emit("input");
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }

    this.loading = false;
    return this.batchRegisters;
  }

  get selectableNonReconciliationMotives() {
    return this.nonReconciliationMotives.filter(
      (motive) => motive.value !== "GERADO_APOS_CONCILIACAO"
    );
  }

  async downloadXLS(): Promise<void> {
    this.loadingXLS = true;
    const [error, data] = await this.service.generateBatchRegistersXls({
      batchId: this.batch.id
    });
    if (!error) {
      window.open(
        process.env.VUE_APP_LOAN_URL! +
          "/batch-registers/download-xls?token=" +
          data!.token,
        "_blank"
      );
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingXLS = false;
  }

  async closeBatchAndGenerateBankSlip(): Promise<void> {
    this.loading = true;
    const [error] = await this.service.closeBatchAndGenerateBankSlip(
      this.batch.id
    );

    this.loading = false;
    if (!error) {
      this.$notify({ type: "success", text: "Lote fechado!" });
      this.$emit("input");
      this.fetchPayments();
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
  }

  async generateBankSlip(): Promise<void> {
    this.loadingPayments = true;
    const [error] = await this.service.generateBankSlip(this.batch.id);
    this.loadingPayments = false;
    if (!error) {
      this.$notify({ type: "success", text: "Boleto gerado!" });
      this.fetchPayments();
    } else {
      this.$notify({
        type: "error",
        text:
          getErrorMessageFromApiError(error) ||
          "Ocorreu um erro ao gerar o boleto!"
      });
    }

    return;
  }

  validateSeverancePayValue(value?: string): boolean {
    if (value) {
      return Number(value) >= 0;
    }
    return false;
  }

  validateReconciliationValue(value?: string): boolean {
    if (value) {
      return Number(value) > 0;
    }
    return false;
  }

  get validateBatchRegisters(): boolean {
    if (this.batchRegistersUpdateData.batchRegisters.length > 0) {
      return this.batchRegistersUpdateData.batchRegisters.every(
        (batchRegister) => {
          if (batchRegister.reconciliate) {
            return this.validateReconciliationValue(
              batchRegister.reconciliationValue
            );
          } else {
            if (batchRegister.nonReconciliationMotive) {
              if (
                batchRegister.nonReconciliationMotive ===
                "FUNCIONARIO_DESLIGADO"
              ) {
                return this.validateSeverancePayValue(
                  batchRegister.severancePayValue
                );
              } else if (
                batchRegister.nonReconciliationMotive === "TRANSFERIR_CONTRATO"
              ) {
                this.portabilityMotive({
                  batchId: this.batchRegistersUpdateData.batchId,
                  batchRegister: batchRegister,
                  companyId: this.batch.companyId
                });
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
          }
        }
      );
    }
    return true;
  }

  reconciliateChanged(index: number): void {
    if (this.batchRegistersUpdateData.batchRegisters[index].reconciliate) {
      this.batchRegistersUpdateData.batchRegisters[index].reconciliationValue =
        this.batchRegisters[index].loanInstallment.value;
    }
    this.batchRegisterChanged(
      this.batchRegistersUpdateData.batchRegisters[index]
    );
  }

  batchRegisterChanged(batchRegister: BatchRegisterUpdateData): void {
    this.changed = true;
  }

  fixBatchRegisterData(
    batchRegister: BatchRegisterUpdateData
  ): BatchRegisterUpdateData {
    if (batchRegister.reconciliate) {
      batchRegister.nonReconciliationMotive = undefined;
      batchRegister.severancePayValue = "0";
    } else {
      batchRegister.reconciliationValue = undefined;
      if (batchRegister.nonReconciliationMotive) {
        if (batchRegister.nonReconciliationMotive !== "FUNCIONARIO_DESLIGADO") {
          batchRegister.severancePayValue = "0";
        }
      } else {
        batchRegister.severancePayValue = "0";
      }
    }
    return batchRegister;
  }

  close(): void {
    this.$emit("close");
  }

  portabilityMotive(batchRegister): void {
    this.$emit("updateBatchRegisterCompany", batchRegister);
  }

  formatDate(date: string): string {
    return dayjs(date).format("DD/MM/YYYY");
  }

  formatNonReconciliationMotive(key?: string): string {
    if (key) {
      const motive = this.nonReconciliationMotives.find((m) => m.value === key);
      if (motive) {
        return motive.text;
      }
    }
    return "";
  }

  get formTitle(): string {
    return "Detalhes do lote " + this.batch.id;
  }

  get canEdit(): boolean {
    return (
      this.editable && this.batch.status.name !== BatchStatusEnum.CLOSED.name
    );
  }

  get installmentsTotal(): number {
    let total = new Decimal(0);
    this.batchRegisters.forEach((batchRegister: ExtendedBatchRegister) => {
      total = total.plus(new Decimal(batchRegister.loanInstallment.value));
    });
    if (this.batchRegistersAfterConciliation.length > 0 && this.isAdminGooroo) {
      this.batchRegistersAfterConciliation.forEach(
        (batchRegister: ExtendedBatchRegister) => {
          total = total.plus(new Decimal(batchRegister.loanInstallment.value));
        }
      );
    }
    return total.toNumber();
  }

  get total(): number {
    let total = new Decimal(0);
    this.batchRegistersUpdateData.batchRegisters.forEach(
      (batchRegister: BatchRegisterUpdateData) => {
        if (batchRegister.reconciliate) {
          if (batchRegister.reconciliationValue) {
            total = total.plus(new Decimal(batchRegister.reconciliationValue));
          }
        } else if (batchRegister.severancePayValue) {
          total = total.plus(new Decimal(batchRegister.severancePayValue));
        }
      }
    );
    return total.toNumber();
  }

  get downloadURL(): string {
    return (
      process.env.VUE_APP_LOAN_URL +
      "/batch-registers/" +
      this.batch.id +
      "/download"
    );
  }

  get isAdminGooroo(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }
}
